<template>
  <main>
    <div class="first-body-section">
      <img
        id="fade"
        class="fade-in"
        src="@/assets/impactpage/Group 2076.svg"
        alt=""
      />
      <img
        class="fade-in responsive-fade"
        src="@/assets/impactpage/Group 2076.svg"
        alt=""
      />
    </div>
    <div class="impact-text-section">
      <span class="impact">Reach and Impact</span>
      <p>
        <b>Matric Live</b> has been in operation for 3 years, and has had quite
        an impact since launch, with the <br />
        number of users doubling each year. The consistent sentiment from app
        store reviews is that <br />
        the platform is handy, helped during lockdown & is a great alternative
        to textbooks.
      </p>
    </div>
    <div class="wrapper">
      <section class="block">
        <div class="each-year">
          <div class="title">2017</div>
          <div class="each-event">
            <div class="event-description">
              <div>
                <img
                  class="img-margin"
                  src="@/assets/impactpage/2017 image.svg"
                  alt=""
                />
              </div>
              <span
                ><b>Matric Live</b> wins LeadSA Youth Hero and <br />
                Donates R10k to Top Achieving <br />Students to Apply and
                Prepare for University</span
              >
            </div>
          </div>
        </div>
      </section>

      <section class="block">
        <div class="each-year">
          <div class="title">2018</div>
          <div class="each-event">
            <div class="event-description">
              <div>
                <img
                  src="@/assets/impactpage/2018 image1.svg"
                  alt=""
                  height="320"
                />
              </div>
            </div>
          </div>

          <div class="each-event">
            <div class="event-description">
              <div>
                <img src="@/assets/impactpage/2018 image2.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="block">
        <div class="each-year">
          <div class="title">2019</div>
          <div class="each-event">
            <div class="event-description">
              <div>
                <img
                  class="img-margin"
                  src="@/assets/impactpage/2019 image1.svg"
                  alt=""
                />
              </div>
              <span>
                <b>Matric Live</b> eAssessment Features get acquired by the
                Department of Education for <b>R500 000,00</b>
              </span>
            </div>
          </div>
          <div class="each-event">
            <div class="event-description">
              <div>
                <img src="@/assets/impactpage/2019 image2.svg" alt="" />
              </div>
              <span>
                <b>Wins MTN App Best Educational Solution</b>
              </span>
            </div>
          </div>
        </div>
      </section>
      <section class="block">
        <div class="each-year">
          <div class="title">2020</div>
          <div class="each-event">
            <div class="event-description">
              <div class="s600k">600k+ Users</div>
            </div>
          </div>
          <div class="each-event">
            <div class="event-description">
              <div>
                <img src="@/assets/impactpage/2020 image1.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: "Impact",
  mounted() {
    const sliders = document.querySelectorAll(".slide-in");

    const appearOptions = {
      threshold: 0,
      rootMargin: "0px 0px -250px 0px",
    };

    const appearOnScroll = new IntersectionObserver(function (
      entries,
      appearOnScroll
    ) {
      entries.forEach((entry) => {
        console.log(entry);
        if (!entry.isIntersecting) {
          return;
        } else {
          entry.target.classList.add("appear");
          appearOnScroll.unobserve(entry.target);
        }
      });
    },
    appearOptions);

    sliders.forEach((slider) => {
      appearOnScroll.observe(slider);
    });

    var scale = document.getElementById("fade");
    setTimeout(() => {
      scale.classList.add("scale-down");
    }, 1500);

    setTimeout(() => {
      scale.style.width = scale.getBoundingClientRect().width + "px";
      
    }, 3000);

    var element = document.querySelectorAll(".each-event, .title");
    var windo = window;
    windo.addEventListener("scroll", check_for_fade);

    windo.dispatchEvent(new Event("scroll"));
    function check_for_fade() {
      var window_height = windo.innerHeight;
      element.forEach((el) => {
        var element_height = el.offsetHeight;
        var rect = el.getBoundingClientRect();
        var offset = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
        };
        var element_offset = offset.top;

        var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;

        var space =
          window_height - (element_height + element_offset - scrollTop);

        if (space < 60) {
          el.classList.add("non-focus");
        } else {
          el.classList.remove("non-focus");
        }
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
main {
  background-image: url("../assets/careerspage/Desktop - 1.svg");
  background-size: auto;
  overflow: hidden;
}

.s600k {
  font-weight: 800;
  font-size: 2.1em;
}

.first-body-section {
  margin: 28px 0px;
  justify-content: space-around;
}

.responsive-fade {
  display: none;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

hr {
  border-top: 2px dashed #e96812;
  width: 33%;
  margin: 5% auto;
}

.from-bottom {
  grid-column: 2 / 3;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.from-bottom {
  transition: opacity 250ms ease-in, -webkit-transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in,
    -webkit-transform 1s ease-in;
  opacity: 0;
}

.from-bottom.appear {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.from-left {
  grid-column: 2 / 3;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.from-left {
  transition: opacity 250ms ease-in, -webkit-transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in,
    -webkit-transform 1s ease-in;
  opacity: 0;
}

.from-left.appear {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.impact-text-section {
  text-align: initial;
  margin: 6%;
}

.impact-text-section .impact {
  text-align: initial;
  font-size: large;
  font-weight: 700;
}

p {
  margin: 3% 0px;
}

.scale-down {
  transform-origin: top;
  animation: scale 1500ms ease-in-out backwards;
}

@keyframes scale {
  to {
    transform: scale(0.6);
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  //   background-color: #42a5f5;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  //   font: normal 16px/26px Open Sans Condensed, sans-serif;
  // color: mediumpurple;
  color: mediumpurple;
  padding: 100px 20px 200px;
  box-sizing: border-box;
}

.block {
  z-index: 1;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: calc(100% - 40px);
    width: 1px;
    background-color: mediumpurple;
    left: 50%;
    margin-top: 90px;
  }
}

.title {
  font: bold 42px/52px Open Sans Condensed, sans-serif;
  text-align: center;
  padding: 20px;
  transition: all 0.8s ease-in-out;
}

.each-event {
  color: rgb(15, 10, 10);
  padding: 15px;
  margin: 60px 20px;
  width: 300px;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  &:before {
    content: "";
    position: absolute;
    top: calc(50% - 8px);
    left: -27px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: mediumpurple;
    box-shadow: 0 4px 20px -4px rgba(64, 64, 64, 0.8);
  }
  &:nth-child(odd) {
    margin-left: calc(50% + 20px);
  }
  &:nth-child(even) {
    margin-left: calc(50% - 321px);
    &:before {
      left: calc(100% + 14px);
    }
  }
}

.title.non-focus {
  font-size: 24px;
  line-height: 34px;
  transition: all 0.8s ease-in-out;
}
span {
  font-size: small;
}
.each-event.non-focus {
  color: #d2d2d2;
  margin-top: 90px;
  transform: rotateZ(45deg);
  transition: all 0.8s ease-in-out;
  &:before {
    opacity: 0;
    background-color: #aaa;
  }
}

div > .img-margin {
  margin-bottom: 6%;
}

@media screen and (max-width: 950px) {
  .responsive-fade {
    display: block;
    width: 350px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  #fade {
    display: none;
  }

  .block {
  z-index: 1;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: calc(100% - 40px);
    width: 1px;
    background-color: mediumpurple;
    left: 0%;
    margin-top: 90px;
  }
}

  .title {
    text-align: initial;
    padding: 20px 0px;
  }

  .each-event {
  
  &:nth-child(odd) {
    margin-left: 20px;
    // margin-left: calc(50% + 20px);
    
  }
  &:nth-child(even) {
    margin-left: 20px;
    // margin-left: calc(50% - 321px);
    &:before {
      // left: -1%;
      left: calc(-16% + 20px);
    }
  }
}

.event-description div img {
  width: 320px;
  height: 170px;
}
}
</style>